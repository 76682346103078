<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Increment Approval</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <!-- <div class="text-center fw-bold fs-4" v-if="currentStatus == 'CHLO'">All Assessors approved</div> -->
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.program_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.batch"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="programDetail.level"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="row mb-3">
                    <label for="name" class="col-md-4 form-label">
                      Effective Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                      <input
                        type="date"
                        disabled
                        v-model="programDetail.effective_date"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!--------------------  Approvement State  ---------------------->
              <div class="mt-3 controllingParent">
                <div class="d-flex">
                  <!-- :class="
                      item.increment_management_approvals ||
                      (item.emp_id == currentUserId &&
                        item.name == currentStatus)
                        ? ' fw-bold active-title'
                        : ''
                    " -->
                  <div v-for="(item, index) in requestAssessors" :key="index">
                    <div
                      class="title me-5"
                      :class="
                        item.active_status == 1 ? ' fw-bold active-title ' : ''
                      "
                      @click="clickNavigation(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>

                <div class="mt-3 overflowTable">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Employee Name</th>
                        <th>Employee ID</th>
                        <th>Joined Date</th>
                        <th>BU/BR/DIV</th>
                        <th>Grade</th>
                        <th v-if="currentAssessor.name == 'Related COO'">
                          Related COO
                        </th>
                        <th>Remark</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in employeeDetail">
                        <td>{{ ++index }}</td>
                        <td>{{ item.employee_name }}</td>
                        <td>{{ item.employee_id }}</td>
                        <td>{{ item.joined_date }}</td>
                        <td>{{ item.bubrdiv_name }}</td>
                        <td>A</td>
                        <td v-if="currentAssessor.name == 'Related COO'">
                          {{ item.r_coo_name }}
                        </td>
                        <td class="d-flex align-items-center">
                          <template
                            v-if="currentAssessor.name == 'Program Owner'"
                          >
                            <input
                              type="checkbox"
                              class="me-2"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                              v-model="item.p_o_remarks_active"
                            />
                            <textarea
                              v-if="item.p_o_remarks_active"
                              v-model="item.p_o_remarks"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                            >
                            </textarea>
                          </template>

                          <template
                            v-else-if="
                              currentAssessor.name == 'Department Head'
                            "
                          >
                            <input
                              type="checkbox"
                              class="me-2"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                              v-model="item.d_h_remarks_active"
                            />
                            <textarea
                              v-if="item.d_h_remarks_active"
                              v-model="item.d_h_remarks"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                            >
                            </textarea>
                          </template>

                          <template
                            v-else-if="currentAssessor.name == 'GM AGM'"
                          >
                            <input
                              type="checkbox"
                              class="me-2"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                              v-model="item.gm_agm_remarks_active"
                            />
                            <textarea
                              v-if="item.gm_agm_remarks_active"
                              v-model="item.gm_agm_remarks"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                            >
                            </textarea>
                          </template>

                          <template
                            v-else-if="currentAssessor.name == 'Related COO'"
                          >
                            <input
                              type="checkbox"
                              class="me-2"
                              :disabled="
                                (Array.isArray(currentActive?.emp_id) &&
                                  currentActive?.emp_id?.some(
                                    (active_emp) =>
                                      !currentAssessor?.assessor_id?.includes(
                                        active_emp
                                      )
                                  )) ||
                                !currentActive?.emp_id?.includes(
                                  $store.state.auth.user.employee_id
                                ) ||
                                currentActive.name !== currentAssessor.name
                              "
                              v-model="item.r_coo_remarks_active"
                            />
                            <textarea
                              v-if="item.r_coo_remarks_active"
                              v-model="item.r_coo_remarks"
                              :disabled="
                                (Array.isArray(currentActive?.emp_id) &&
                                  currentActive?.emp_id?.some(
                                    (active_emp) =>
                                      !currentAssessor?.assessor_id?.includes(
                                        active_emp
                                      )
                                  )) ||
                                !currentActive?.emp_id?.includes(
                                  $store.state.auth.user.employee_id
                                ) ||
                                currentActive.name != currentAssessor.name
                              "
                            >
                            </textarea>
                          </template>

                          <template v-else-if="currentAssessor.name == 'CHLO'">
                            <input
                              type="checkbox"
                              class="me-2"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                              v-model="item.chlo_remarks_active"
                            />
                            <textarea
                              v-if="item.chlo_remarks_active"
                              v-model="item.chlo_remarks"
                              :disabled="
                                currentActive.emp_id !=
                                  currentAssessor.assessor_id ||
                                currentActive.emp_id !=
                                  $store.state.auth.user.employee_id ||
                                currentActive.name != currentAssessor.name
                              "
                            >
                            </textarea>
                          </template>
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'grading-evaluation-management-update',
                              // params: {
                              //   id: item.grading_evaluations
                              //     .grading_evaluation_detail.id,
                              // },
                            }"
                          >
                            <button
                              type="button"
                              class="btn btn-xs btn-blue waves-effect waves-light mb-2"
                            >
                              <i class="fas fa-eye"></i>
                              View
                            </button>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="progress mt-2"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="mt-3">
                <div class="d-flex align-items-center">
                  <p class="fw-bold">
                    {{ currentAssessor.name }}
                  </p>
                  <div class="mx-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="currentAssessor.assessor_name"
                      disabled
                    />
                  </div>
                  <div>
                    <button class="buttonStyle bg-success" disabled>
                      Approved By
                    </button>
                  </div>
                </div>
              </div>

              <template
                v-if="
                  !activeRelatedCoo.includes($store.state.auth.user.employee_id)
                "
              >
                <div
                  class="row mt-3"
                  v-if="
                    currentActive.emp_id == currentAssessor.assessor_id &&
                    currentActive.emp_id ==
                      $store.state.auth.user.employee_id &&
                    currentActive.name == currentAssessor.name
                  "
                >
                  <div class="col-md-12">
                    <div class="text-center mt-3 mb-3">
                      <button
                        v-if="currentAssessor.name != 'Program Owner'"
                        :disabled="isLoadingCancel ? true : false"
                        type="button"
                        @click="reviseApproval()"
                        class="btn w-sm btn-secondary me-3"
                      >
                        <span
                          v-if="isLoadingCancel"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoadingCancel == true ? "Loading..." : "Revise" }}
                      </button>

                      <button
                        :disabled="isLoading ? true : false"
                        type="button"
                        @click="confirmApproval()"
                        class="btn w-sm btn-success waves-effect waves-light px-3"
                      >
                        <span
                          v-if="isLoading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoading == true ? "Loading..." : "Submit" }}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="row mt-3"
                  v-else-if="
                    currentActive.name == 'Related COO' &&
                    Array.isArray(currentActive?.emp_id) &&
                    currentActive?.emp_id?.some((active_emp) =>
                      currentAssessor?.assessor_id?.includes(active_emp)
                    ) &&
                    currentActive?.emp_id?.includes(
                      $store.state.auth.user.employee_id
                    )
                  "
                >
                  <div class="col-md-12">
                    <div class="text-center mt-3 mb-3">
                      <button
                        v-if="currentAssessor.name != 'Program Owner'"
                        :disabled="isLoadingCancel ? true : false"
                        type="button"
                        @click="reviseApproval()"
                        class="btn w-sm btn-secondary me-3"
                      >
                        <span
                          v-if="isLoadingCancel"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoadingCancel == true ? "Loading..." : "Revise" }}
                      </button>

                      <button
                        :disabled="isLoading ? true : false"
                        type="button"
                        @click="confirmApproval()"
                        class="btn w-sm btn-success waves-effect waves-light px-3"
                      >
                        <span
                          v-if="isLoading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoading == true ? "Loading..." : "Submit" }}
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import validationErrors from "../../../mixins/validationErrors";
export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      programDetail: {},
      employeeDetail: [],
      employeeLists: [],
      currentAssessor: "",
      currentActive: {},
      activeRelatedCoo: [],
      requestAssessors: [
        {
          active_status: false,
          name: "Program Owner",
          assessor_name: "",
          active_approve: false,
          assessor_id: "",
          assessor_details: {},
        },
        {
          active_status: false,
          name: "Department Head",
          assessor_name: "",
          active_approve: false,
          assessor_id: "",
          assessor_details: {},
        },
        {
          active_status: false,
          name: "GM AGM",
          assessor_name: "",
          active_approve: false,
          assessor_id: "",
          assessor_details: {},
        },
        {
          active_status: false,
          name: "Related COO",
          assessor_name: [],
          active_approve: false,
          assessor_id: [],
          assessor_details: [],
        },
        {
          active_status: false,
          name: "CHLO",
          assessor_name: "",
          active_approve: false,
          assessor_id: "",
          assessor_details: {},
        },
      ],
      assessorList: [],
      isLoading: false,
      isLoadingCancel: false,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    clickNavigation(item) {
      this.requestAssessors.forEach((assessor) => {
        assessor.active_status = false;
      });
      item.active_status = true;
      this.currentAssessor = item;
      if (Array.isArray(item.assessor_name)) {
        this.currentAssessor.assessor_name = item.assessor_name.join(", ");
      } else {
        this.currentAssessor.assessor_name = item.assessor_name;
      }
    },
    async getApprovalData() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/increment-management-request-approve/${this.$route.params.id}`
        )
        .then(async (response) => {
          if (!response.data.data) return;

          this.currentActive = response.data.data.currentActive;
          this.activeRelatedCoo = response.data.data.activeRelatedCooList;
          const data =
            response.data.data.incrementManagement[0].grading_evaluations
              .grading_evaluation_detail;
          this.programDetail = {
            program_name: data.program_name,
            batch: data.program_batch_no,
            level: data.level,
            effective_date:
              response.data.data.incrementManagement[0].effective_date,
          };

          this.employeeDetail = response.data.data.incrementManagement.map(
            (res) => {
              let bubrdiv = "";
              this.employeeLists.forEach((empList) => {
                if (empList.emp_id == res.p_o_id) {
                  res.p_o_remarks_active = res?.p_o_remarks ? true : false;
                  this.requestAssessors[0].assessor_name = empList.name;
                  this.requestAssessors[0].assessor_id = res.p_o_id;
                  this.requestAssessors[0].assessor_details = {
                    assessor_emp_id: res.p_o_id,
                    assessor_status: res?.p_o_status,
                    assessor_remark: res?.p_o_remarks,
                  };
                }
                if (empList.emp_id == res.d_h_id) {
                  res.d_h_remarks_active = res?.d_h_remarks ? true : false;
                  this.requestAssessors[1].assessor_name = empList.name;
                  this.requestAssessors[1].assessor_id = res.d_h_id;
                  this.requestAssessors[1].assessor_details = {
                    assessor_emp_id: res.d_h_id,
                    assessor_status: res?.d_h_status ?? 0,
                    assessor_remark: res?.d_h_remarks ?? null,
                  };
                }
                if (empList.emp_id == res.gm_agm_id) {
                  res.gm_agm_remarks_active = res?.gm_agm_remarks
                    ? true
                    : false;
                  this.requestAssessors[2].assessor_name = empList.name;
                  this.requestAssessors[2].assessor_id = res.gm_agm_id;
                  this.requestAssessors[2].assessor_details = {
                    assessor_emp_id: res.gm_agm_id,
                    assessor_status: res?.gm_agm_status ?? 0,
                    assessor_remark: res?.gm_agm_remarks ?? null,
                  };
                }
                if (empList.emp_id == res.chlo_id) {
                  res.chlo_remarks_active = res?.chlo_remarks ? true : false;
                  this.requestAssessors[4].assessor_name = empList.name;
                  this.requestAssessors[4].assessor_id = res.chlo_id;
                  this.requestAssessors[4].assessor_details = {
                    assessor_emp_id: res.chlo_id,
                    assessor_status: res?.chlo_status ?? 0,
                    assessor_remark: res?.chlo_remarks ?? null,
                  };
                }
                if (empList.emp_id == res.r_coo_id) {
                  res.r_coo_remarks_active = res?.r_coo_remarks ? true : false;

                  this.requestAssessors[3].assessor_name.push(empList.name);
                  this.requestAssessors[3].assessor_id.push(res.r_coo_id);
                  this.requestAssessors[3].assessor_details.push({
                    assessor_emp_id: res.r_coo_id,
                    assessor_status: res.r_coo_status,
                    assessor_remark: res.r_coo_remarks,
                  });
                  res.r_coo_name = this.employeeLists?.find(
                    (emp) => emp.emp_id == res.r_coo_id
                  )?.name;
                }
                if (
                  empList.emp_id ==
                  res.grading_evaluations.grading_evaluation_detail.employee_id
                )
                  bubrdiv = empList.business_unit[0]?.name;
              });

              return {
                id: res.id,
                grading_evaluation_detail_id:
                  res.grading_evaluations?.grading_evaluation_detail_id,
                employee_id:
                  res.grading_evaluations?.grading_evaluation_detail
                    ?.employee_id,
                p_o_remarks_active: res.p_o_remarks_active,
                p_o_remarks: res.p_o_remarks,
                d_h_remarks_active: res.d_h_remarks_active,
                d_h_remarks: res.d_h_remarks,
                gm_agm_remarks_active: res.gm_agm_remarks_active,
                gm_agm_remarks: res.gm_agm_remarks,
                r_coo_remarks_active: res.r_coo_remarks_active,
                r_coo_remarks: res.r_coo_remarks,
                chlo_remarks_active: res.chlo_remarks_active,
                chlo_remarks: res.chlo_remarks,
                r_coo_name: res.r_coo_name,
                employee_name:
                  res.grading_evaluations?.grading_evaluation_detail
                    ?.employee_name,
                joined_date:
                  res.grading_evaluations?.grading_evaluation_detail?.join_date,
                bubrdiv_name: bubrdiv, // Assuming you want only the first business unit name
              };
            }
          );

          this.getAssessorCheck();

          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is");
          this.toast.error("Something went wrong.");
        });

      this.loading = false;
      this.$Progress.finish();
    },
    getAssessorCheck() {
      let status = false;
      for (let i = 0; i < this.requestAssessors.length; i++) {
        const item = this.requestAssessors[i];
        if (this.currentActive.name == "Related COO") {
          if (
            item.name == this.currentActive?.name &&
            this.currentActive.emp_id.includes(
              this.$store.state.auth.user.employee_id
            )
          ) {
            this.clickNavigation(item);
            item.active_approve = true;
            this.activeRemark = true;
            status = true;
            break;
          }
        } else if (
          item.name == this.currentActive?.name &&
          this.$store.state.auth.user.employee_id == this.currentActive.emp_id
        ) {
          this.clickNavigation(item);
          item.active_approve = true;
          status = true;
          break;
        }
      }

      if (!status) {
        for (let i = 0; i < this.requestAssessors.length; i++) {
          const item = this.requestAssessors[i];
          if (item.name == "Related COO") {
            if (
              item.assessor_id.includes(this.$store.state.auth.user.employee_id)
            ) {
              this.clickNavigation(item);
              break;
            }
          } else if (
            item.assessor_id == this.$store.state.auth.user.employee_id
          ) {
            this.clickNavigation(item);
            break;
          } else {
            this.clickNavigation(this.requestAssessors[0]);
          }

          // const isContainAssessor = this.assessorList.map(  this.$store.state.auth.user.employee_id);
          // if (isContainAssessor) return this.clickNavigation(this.requestAssessors.filter(assessor => assessor.assessor_id == ))
        }
      }
      //  else {
      // this.clickNavigation(this.requestAssessors[0]);
      // }
    },

    async confirmApproval() {
      const data = [];
      // this.isLoading = true;
      let r_coo_state = null;
      let approveState = "";
      let showErrors = false;

      this.employeeDetail.forEach((item) => {
        if (this.currentActive.name == "Program Owner") {
          approveState = "p_o_status";
          if (item.p_o_remarks_active && item.p_o_remarks) {
            showErrors = true;
          } else if (!item.p_o_remarks_active) {
            item.p_o_remarks = null;
          }
        } else if (this.currentActive.name == "Department Head") {
          approveState = "d_h_status";
          if (item.d_h_remarks_active && !item.d_h_remarks) {
            showErrors = true;
          } else if (!item.d_h_remarks_active) {
            item.d_h_remarks = null;
          }
        } else if (this.currentActive.name == "GM AGM") {
          approveState = "gm_agm_status";
          if (item.gm_agm_remarks_active && !item.gm_agm_remarks) {
            showErrors = true;
          } else if (!item.gm_agm_remarks_active) {
            item.gm_agm_remarks = null;
          }
        } else if (this.currentActive.name == "Related COO") {
          approveState = "r_coo_status";
          r_coo_state = this.$store.state.auth.user.employee_id;
          if (item.r_coo_remarks_active && !item.r_coo_remarks) {
            showErrors = true;
          } else if (!item.r_coo_remarks_active) {
            item.r_coo_remarks = null;
          }
        } else if (this.currentActive.name == "CHLO") {
          approveState = "chlo_status";
          if (item.chlo_remarks_active && !item.chlo_remarks) {
            showErrors = true;
          } else if (!item.chlo_remarks_active) {
            item.chlo_remarks = null;
          }
        }

        // if(item)
        data.push({
          id: item.id,
          p_o_remarks: item.p_o_remarks,
          d_h_remarks: item.d_h_remarks,
          gm_agm_remarks: item.gm_agm_remarks,
          r_coo_remarks: item.r_coo_remarks,
          chlo_remarks: item.chlo_remarks,
        });
      });

      if (showErrors) return this.toast.error("Please fill remark!");

      const toSendData = {
        detail: data,
        r_coo_state: r_coo_state,
        approveState: approveState,
      };

      this.isLoading = true;
      await axios
        .post(
          `${this.baseUrl}admin/v2/increment-management-confirm`,
          toSendData
        )
        .then((response) => {
          this.toast.success("Confirmed Approval Successfully.");
          this.loading = false;
          this.$router.push({ name: "increment-management-approval" });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.toast.error("Something went wrong!");
        });
    },
    async reviseApproval() {
      let approveState = "";

      let data = [];
      let showErrors = false;
      let isRemark = false;
      this.employeeDetail.forEach((item) => {
        if (this.currentActive.name == "Program Owner") {
          approveState = "p_o_status";
          if (item.p_o_remarks_active && !item.p_o_remarks) {
            showErrors = true;
          } else if (!item.p_o_remarks_active) {
            item.p_o_remarks = null;
          } else if (item.p_o_remarks_active && item.p_o_remarks) {
            isRemark = true;
          }
        } else if (this.currentActive.name == "Department Head") {
          approveState = "d_h_status";
          if (item.d_h_remarks_active && !item.d_h_remarks) {
            showErrors = true;
          } else if (!item.d_h_remarks_active) {
            item.d_h_remarks = null;
          } else if (item.d_h_remarks_active && item.d_h_remarks) {
            isRemark = true;
          }
        } else if (this.currentActive.name == "GM AGM") {
          approveState = "gm_agm_status";
          if (item.gm_agm_remarks_active && !item.gm_agm_remarks) {
            showErrors = true;
          } else if (!item.gm_agm_remarks_active) {
            item.gm_agm_remarks = null;
          } else if (item.gm_agm_remarks_active && item.gm_agm_remarks) {
            isRemark = true;
          }
        } else if (this.currentActive.name == "Related COO") {
          approveState = "r_coo_status";
          if (item.r_coo_remarks_active && !item.r_coo_remarks) {
            showErrors = true;
          } else if (!item.r_coo_remarks_active) {
            item.r_coo_remarks = null;
          } else if (item.r_coo_remarks_active && item.r_coo_remarks) {
            isRemark = true;
          }
        } else if (this.currentActive.name == "CHLO") {
          approveState = "chlo_status";
          if (item.chlo_remarks_active && !item.chlo_remarks) {
            showErrors = true;
          } else if (!item.chlo_remarks_active) {
            item.chlo_remarks = null;
          } else if (item.chlo_remarks_active && item.chlo_remarks) {
            isRemark = true;
          }
        }

        data.push({
          id: item.id,
          p_o_remarks: item.p_o_remarks,
          d_h_remarks: item.d_h_remarks,
          gm_agm_remarks: item.gm_agm_remarks,
          r_coo_remarks: item.r_coo_remarks,
          chlo_remarks: item.chlo_remarks,
        });
      });
      // console.log(isRemark, "is remarking");

      if (showErrors) return this.toast.error("Please fill remark!");
      else if (!isRemark)
        return this.toast.error("Please fill remark to revise!");
      this.isLoadingCancel = true;

      const toSendData = {
        detail: data,
        approveState: approveState,
      };

      await axios
        .post(`${this.baseUrl}admin/v2/increment-management-revise`, toSendData)
        .then((response) => {
          this.toast.success("Revised Approval Successfully");
          this.isLoadingCancel = false;
          this.$router.push({ name: "increment-management-approval" });
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingCancel = false;
          this.toast.error("Something went wrong!");
        });
    },
    getEmployee() {
      this.loading = true;
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
      if (this.$store.getters["odoo/getAllEmployees"].length == 0) {
        setTimeout(() => {
          this.getEmployee();
        }, 3000);
      } else {
        this.getApprovalData();
      }
    },
  },

  created() {
    this.getEmployee();
  },
};
</script>

<style scoped>
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset;
  border-style: unset;
  border-width: unset;
}
.title {
  cursor: pointer;
  user-select: none;
}
.active-title {
  position: relative;
}
.active-title::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: blue;
}
.buttonStyle {
  padding: 0.5em;
  /* background-color: gray; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  border: none;
}
.overflowTable {
  min-width: 900px;
  /* overflow-x: auto; */
}
.controllingParent {
  overflow-x: auto;
}
</style>
